/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-checkbox-label.sc-ion-alert-md {
    white-space: pre-wrap !important;
}

.alert-radio-label.sc-ion-alert-md {
    white-space: pre-wrap !important;
}

.alertDanger {
    background-color: red;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertGiallo {
    background-color: yellow;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertVerde {
    background-color: green;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertBlu {
    background-color: blue;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertRossoBordo {
    outline: 2px solid red;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertVerdeBordo {
    outline: 2px solid green;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertBluBordo {
    outline: 2px solid blue;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertViola {
    background-color: rebeccapurple;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertMarrone {
    background-color: rgb(150, 103, 59);
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertCoralligeno {
    background-color: #AE262B;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertCymodea {
    background-color: #B300FF;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertAncoraggioLiberoVentotene {
    background-color: #3a32a8;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alertPosidoniaVentotene {
    background-color: #f70c0c;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 8px;
}

.alert-ios .alert-message,
.alert-md .alert-message {
    max-height: 300px;
    color: orange;
}